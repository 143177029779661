import * as Sentry from '@sentry/svelte'
import { mutate, query } from '@urql/svelte'
import { parseISO as _parseISO } from 'date-fns'
import { debounce as _debounce } from 'lodash-es'
import { derived } from 'svelte/store'
import { router } from './router'
import { user } from './store.js'
import { ftl, locale, parse_localized } from '/@/multilang'

ftl`
    attachment-error-upload = File upload failed
    attachment-error-upload-rejected = Rejected by server
    attachment-error-upload-read = Failed to read file
    attachment-error-upload-size = File must be smaller than {$param}
    attachment-error-upload-type = File must be one of {$param}
    attachment-error-upload-risk = File looks dangerous
    timespan-validity-later = Later
    timespan-validity-expired = expired
    timespan-validity-starts = From {$start}
    timespan-validity-ends = Until {$end}
    timespan-validity-spans = {$start} until {$end}
    `

export const parseISO = (dd) =>
  dd ? (dd instanceof Date ? dd : _parseISO(dd)) : undefined

export function getDocumentList(schema, callback) {
  schema = `list${schema}Entry`
  return query({
    query: `query {${schema}{nodes{uuid, title, timestamp, validity_start, validity_end, validity_user, categories{title}}}}`,
    requestPolicy: 'cache-and-network',
    context: { additionalTypenames: ['Document'] },
  }).subscribe((e) => {
    if (!e.data) return
    const data = e.data[schema] ? e.data[schema].nodes : []
    callback(
      data.map((rr) => ({
        ...rr,
        title: parse_localized(rr.title),
        timestamp: parseISO(rr.timestamp),
        validity_start: parseISO(rr.validity_start),
        validity_end: parseISO(rr.validity_end),
        categories: rr.categories.map((cc) => parse_localized(cc.title)),
      }))
    )
  })
}

export function getDocument(schema, uuid, callback) {
  schema = `get${schema}Entry`
  return query({
    query: `query($uuid: ID!) {${schema}(uuid: $uuid){title, content, timestamp, category_type, categories{uuid, title}, scopes{uuid, name}, validity_start, validity_end, validity_user,  attachedFiles{nodes{uuid, name, size, mimetype, uri}}}}`,
    variables: { uuid: uuid },
    requestPolicy: 'cache-and-network',
    context: { additionalTypenames: ['FileAttachment', 'Category', 'Scope'] },
  }).subscribe((e) => {
    if (e && e.data && e.data[schema]) {
      const doc2 = e.data[schema]
      callback({
        ...doc2,
        categories: doc2.categories.map((cc) => ({
          ...cc,
          title: parse_localized(cc.title),
        })),
        title: parse_localized(doc2.title),
        content: parse_localized(doc2.content),
        validity_start: parseISO(doc2.validity_start),
        validity_end: parseISO(doc2.validity_end),
        validity_user: doc2.validity_user,
        timestamp: parseISO(doc2.timestamp),
        files: doc2.attachedFiles.nodes.map((ii) => ({
          uuid: ii.uuid,
          name: ii.name,
          size: ii.size,
          mimetype: ii.mimetype,
          uri: ii.uri,
          status: 'success',
        })),
      })
    }
  })
}

export async function putDocument(schema, uuid, data, target) {
  // Ignore submit while files are loading...
  if (
    data.files.filter((ii) => ii.status === 'pending' || ii.error).length != 0
  )
    return

  let params = {
    title: JSON.stringify(data.title || {}),
    content: JSON.stringify(data.content || {}),
    categories: data.categories ? data.categories.map((i) => i.uuid) : [],
    scopes: data.scopes ? data.scopes.map((i) => i.uuid) : [],
    validity_start: data.validity_start || null,
    validity_end: data.validity_end || null,
    validity_user: data.validity_user,
    addedFiles: data.files
      .filter((ii) => ii.status === 'success' && ii.content !== undefined)
      .map((ii) => ({
        uuid: ii.uuid,
        name: ii.name,
        size: ii.size,
        mimetype: ii.mimetype,
        content: ii.content,
      })),
    deletedFiles: data.files
      .filter((ii) => ii.status === 'deleted')
      .map((ii) => ii.uuid),
  }

  let args

  if (uuid)
    args = {
      query: `mutation($uuid: ID!, $data:DocumentInput!){update${schema}Entry(uuid: $uuid, data:$data){uuid}}`,
      variables: {
        data: params,
        uuid: uuid,
      },
    }
  else
    args = {
      query: `mutation($data:DocumentInput!){create${schema}Entry(data:$data){uuid}}`,
      variables: {
        data: params,
      },
    }

  await mutate(args).then((res) => {
    if (res.error || (res.error = res.errors?.[0]))
      throw parseFileUploadError(res.error.message)
    else router.goto(target)
  })

  // FIXME: pending oder so?
  // FIXME: exceptions
}

export function getCategories(schema, callback) {
  schema = `list${schema}Category`
  return query({
    query: `query{${schema}{nodes{uuid, title}}}`,
    requestPolicy: 'cache-and-network',
    context: { additionalTypenames: ['Category'] },
  }).subscribe((e) => {
    if (!e.data) return

    const data = e.data[schema] ? e.data[schema].nodes : []
    callback(
      data.map((cc) => ({ uuid: cc.uuid, title: parse_localized(cc.title) }))
    )
  })
}

export function getCategory(schema, uuid, callback) {
  schema = `get${schema}Category`
  return query({
    query: `query($uuid: ID!){${schema}(uuid: $uuid){uuid, title}}`,
    variables: { uuid: uuid },
    requestPolicy: 'cache-and-network',
  }).subscribe((e) => {
    if (e.data && e.data[schema]) {
      const data = e.data[schema]
      callback({ id: data.uuid, title: parse_localized(data.title) })
    }
  })
}

export async function putCategory(schema, uuid, data, target) {
  let args

  let params = {
    title: JSON.stringify(data.title || {}),
  }

  if (uuid)
    args = {
      query: `mutation($uuid: ID!, $data:CategoryInput!){update${schema}Category(uuid: $uuid, data:$data){uuid}}`,
      variables: {
        data: params,
        uuid: uuid,
      },
    }
  else
    args = {
      query: `mutation($data:CategoryInput!){create${schema}Category(data:$data){uuid}}`,
      variables: {
        data: params,
      },
    }

  await mutate(args).then(router.goto(target))

  // FIXME: pending oder so?
  // FIXME: exceptions
}

import { jsLocalize } from '/@/multilang/utils.js'

// formatActor
import { translate } from '/@/multilang/i18n'

ftl`
    format-role-prefix = Role {$name}
    format-actor-not-available = Not available
    `

export const formatInmate = (aa) => {
  // FIXME: inmate_id
  if (!aa) return translate('format-actor-not-available').text
  return aa.last_name
    ? aa.first_name
      ? `${aa.last_name}, ${aa.first_name} (${aa.username})`
      : `${aa.last_name} (${aa.username})`
    : aa.username
}

export const formatStaff = (aa) => {
  if (!aa) return translate('format-actor-not-available').text

  return aa.last_name
    ? aa.first_name
      ? `${aa.last_name}, ${aa.first_name}${
          user.is_staff() ? ` (${aa.username})` : ''
        }`
      : `${aa.last_name} ${user.is_staff() ? ` (${aa.username})` : ''}`
    : aa.username
}

export const formatRole = (aa, lang) => {
  return translate('format-role-prefix', {
    name: jsLocalize(parse_localized(aa.name), lang),
  }).text
}

export const jsFormatActor = (aa, lang) => {
  if (!aa) return translate('format-actor-not-available').text
  if (['StaffRole', 'FullStaffRole'].includes(aa.__typename))
    return formatRole(aa, lang)
  else if (['Staff', 'FullStaff'].includes(aa.__typename))
    return formatStaff(aa)
  else if (['Inmate', 'FullInmate'].includes(aa.__typename))
    return formatInmate(aa)
  else return aa.username
}

export const formatActor = derived(
  locale,
  ($locale) => (aa) => jsFormatActor(aa, $locale)
)

import DOMPurify from 'dompurify'

export const sanitizeHtml = (txt) => DOMPurify.sanitize(txt)

// showdown
import showdown from 'showdown'

showdown.extension('stripper', function () {
  return [
    {
      type: 'output',
      // eslint-disable-next-line no-unused-vars
      filter: function (txt, conv, opts) {
        var finalTxt = ''
        txt = sanitizeHtml(txt)
        if (txt) {
          var div = document.createElement('div')
          div.innerHTML = txt
          for (var i = 0; i < div.children.length; ++i) {
            if (div.children[i].tagName.toLowerCase() === 'p') {
              finalTxt += div.children[i].innerHTML
            }
          }
        }
        return finalTxt
      },
    },
  ]
})

// escape, from https://github.com/theSmaw/Caja-HTML-Sanitizer/blob/master/sanitizer.js#L197
var ampRe = /&/g
var ltRe = /[<]/g
var gtRe = />/g
var quotRe = /"/g

// Escapes HTML special characters in attribute values.
export const escape = (s) => {
  if (s) {
    return ('' + s)
      .replace(ampRe, '&amp;')
      .replace(ltRe, '&lt;')
      .replace(gtRe, '&gt;')
      .replace(quotRe, '&#34;')
  } else {
    return s
  }
}

const markdown = new showdown.Converter({
  strikethrough: true,
  extensions: ['stripper'],
})

export const makeHtml = (txt) => markdown.makeHtml(txt)

const FADE_DURATION = 200
export const popperBackdropModifier = {
  name: 'backdrop',
  phase: 'write',
  enabled: true,
  fn() {},
  effect({ state }) {
    let el = document.createElement('div')
    el.setAttribute('id', 'popper-backdrop')
    el.setAttribute(
      'style',
      [
        'backdrop-filter:blur(1px)',
        'opacity:0',
        `transition: opacity ${FADE_DURATION}ms ease-out;`,
      ].join(';')
    )
    el.classList.add(
      'fixed',
      'top-0',
      'left-0',
      'h-full',
      'w-full',
      'bg-secondary-trans',
      'z-10'
    )
    state.elements.popper.parentElement.appendChild(el)

    state.modifiersData.elBackDrop = el

    // trigger fade-in
    setTimeout(() => (el.style.opacity = 1))

    return () => {
      // retire backdrop
      el.style.opacity = 0
      setTimeout(() => {
        el.parentElement?.removeChild(el)
      }, FADE_DURATION)
    }
  },
}

export function isSoon(someDate) {
  if (!someDate || !(someDate instanceof Date))
    return console.error(`isSoon expects a Date`, someDate)

  const result = someDate - Date.now() <= 1000 * 60 * 60 * 24 * 7

  return result
}

export function parseFileUploadError(errorMessage) {
  let oError

  try {
    const m = errorMessage.match(/\[GraphQL\] (?<json>.+)/)
    oError = m ? JSON.parse(m.groups?.json) : null
  } catch (e) {
    console.error('parseFileUploadError failed', errorMessage)
    return {}
  }

  if (!oError) return {}

  const { uuid, type, param } = oError

  return {
    fileUUID: uuid,
    fluentMessage: type ? [type, { param }] : ['attachment-error-upload'],
  }
}

export function captureMessage(message, ctx) {
  // eslint-disable-next-line no-undef
  if (import.meta.env.MODE === 'production') Sentry.captureMessage(message, ctx)
}

export const debounce = (func, ...args) => {
  const helper = () => {
    // console.log("debounce", func, args)
    func(...args)
  }
  // console.log("debounceCall", func, args)
  return _debounce(helper, 200, { leading: true, trailing: false })
}

// Alerting tone
let audioCtx, audioSource
let audioBuffers = new Map()

export const playSound = async (path = '/files/notification.mp3') => {
  let buffer

  if (!audioBuffers.has(path)) {
    try {
      // avoid doing this in parallel...
      audioBuffers.set(path, false)

      audioCtx = new AudioContext()

      buffer = await fetch(path)
      buffer = await buffer.arrayBuffer()
      buffer = await audioCtx.decodeAudioData(buffer)

      audioBuffers.set(path, buffer)
    } catch (err) {
      audioBuffers.delete(path)
      console.error(err)
    }
  } else {
    buffer = audioBuffers.get(path)
  }

  if (!audioBuffers.get(path)) return

  try {
    if (audioCtx.state === 'suspended') audioCtx.resume()

    if (audioSource) {
      audioSource.stop()
      audioSource.disconnect()
    }

    audioSource = audioCtx.createBufferSource()
    audioSource.buffer = buffer
    audioSource.connect(audioCtx.destination)

    audioSource.start(0)
  } catch (e) {
    console.error(e)
  }
}
