import {
  cacheExchange,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
} from '@urql/core'
import { retryExchange } from '@urql/exchange-retry'
import { initClient } from '@urql/svelte'
import { setLocale } from '/@/multilang/i18n'
import axios from 'axios'
import Cookie from 'cookie-universal'
import { fromPairs, get, intersection, isEmpty, keys, set } from 'lodash-es'
import shortuuid from 'short-uuid'
// import { SubscriptionClient } from '@urql/exchange-graphcache'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { derived, writable } from 'svelte/store'

import { router } from './router'

export const cookies = Cookie()

// User Store mit zusätzlichen Funktionen
function _user_store() {
  let user = {
    uuid: null,
    is_superuser: false,
    permissions: {},
    username: '',
    language: undefined,
    notifications: {},
    impersonator: null,
    impersonating: null,

    has_permission: function (perm, val) {
      if (!this.uuid) return false
      if (settings.context !== 'staff') return true
      if (!perm) return true
      if (this.is_superuser) return true
      val = val || 1
      return val <= (this.permissions[perm] || 0)
    },
    has_any: function (perms) {
      if (!this.uuid) return false
      if (!perms || perms.length == 0) return true
      if (this.is_superuser) return true
      return intersection(keys(this.permissions), perms).length != 0
    },
  }

  const { subscribe, set } = writable(user)

  return {
    subscribe,
    set: (val) => {
      user = {
        ...user,
        uuid: val?.uuid,
        model: val?.model,
        impersonator: val?.impersonator,
        impersonating: val?.impersonating,
      }
      set(user)
    },
    set_profile: (profile) => {
      user = {
        ...user,
        username: profile.username,
        is_superuser: profile.is_superuser,
        permissions: profile.permissions
          ? fromPairs(
              profile.permissions.map((pp) => [pp.permission, pp.value])
            )
          : {},
        notifications: profile.notifications,
      }
      set(user)
    },
    set_error: (ftlKey) => {
      user = {
        ...user,
        error: ftlKey,
        uuid: null,
        is_superuser: false,
        permissions: {},
        username: '',
        language: undefined,
      }
      set(user)
    },
    is_staff: () => user.model === 'Staff',

    may_impersonate: () => {
      return user.is_superuser && !user.impersonating
    },
    impersonate: (uuid) => {
      api.get(`/auth/impersonate/${uuid}`).then((e) => {
        user = {
          ...user,
          uuid: e.data.uuid,
          model: e.data.model,
          impersonator: e.data.impersonator,
          impersonating: e.data.impersonating,
        }
        set(user)
        router.goto('/dashboard')
      })
    },
    revert: () => {
      api.get('/auth/stop_impersonation').then((e) => {
        user = {
          ...user,
          uuid: e.data.uuid,
          model: e.data.model,
          impersonator: null,
          impersonating: null,
        }
        set(user)
        // fixme: router.goto?
      })
    },
  }
}

export const user = _user_store()

// PC Identifier
let storage_key = 'CC3_ClientBrowserSettings_ID'
export let browser_id = localStorage.getItem(storage_key)

if (browser_id === null) {
  browser_id = shortuuid.generate()
  localStorage.setItem(storage_key, browser_id)
}

// AXIOS API
export const api = axios.create({
  baseURL: `${window.location.origin}/api/v1`,
  headers: { 'X-Browser-Identifier': browser_id },
  withCredentials: true,
  timeout: 10000,
})

// Auth Token
let authTimer = null
let authNbFailures = 0

const AUTH_REFRESH_INTERVAL = 60000
const AUTH_REFRESH_NB_RETRIES = 2

user.subscribe((current) => {
  clearInterval(authTimer)
  authTimer = null

  if (current.uuid) {
    authTimer = setInterval(() => {
      api
        .get(`/auth/refresh/${settings.context}/`)
        .then((e) => {
          if (authNbFailures) console.log(`Auth server reachable again`)
          authNbFailures = 0
          router.needsReload(e?.data?.rev_short)
        })
        .catch(() => {
          if (++authNbFailures >= AUTH_REFRESH_NB_RETRIES) {
            user.set_error(`${settings.context}-error-disconnection`)
          }
          console.log(
            `Auth server unreachable  ${authNbFailures}/${AUTH_REFRESH_NB_RETRIES}`
          )
        })
    }, AUTH_REFRESH_INTERVAL)
  }
})

export function login(username, password) {
  api
    .post('/auth/login/', { username: username, password: password })
    .then((res) => {
      user.set(res.data)
      setLocale(res.data.language)
    })
}

export function logout() {
  api.get('/auth/logout/').then(() => {
    user.set(null)
    router.goto('/')
  })
}

// GraphQL client
const graphql_url = '/graphql/'
const graphql_ws = `${window.location.origin.replace(
  'http',
  'ws'
)}${graphql_url}?browser_id=${browser_id}`

const subscriptionClient = new SubscriptionClient(graphql_ws, {
  reconnect: true,
  minTimeout: 10000,
  lazy: false,
})

export const connectionState = writable(false)

subscriptionClient.onConnected(() => connectionState.set(true))
subscriptionClient.onReconnected(() => connectionState.set(true))
subscriptionClient.onDisconnected(() => connectionState.set(false))

let currentUserUuid

user.subscribe(({ uuid }) => {
  if (uuid == currentUserUuid) return

  currentUserUuid = uuid
  subscriptionClient.close(!uuid, true)
})

initClient({
  url: graphql_url,
  requestPolicy: 'cache-and-network',
  exchanges: [
    dedupExchange,
    cacheExchange,
    // Variante mit graphcache
    // Erfordert aber einiges an Konfiguration, wäre also etwas für später...
    // cacheExchange({
    //   keys: {
    //     Form: (data) => data.uuid,
    //   },
    //   updates: {
    //     Mutation: {
    //       deleteForm: (result, args, cache, info) => {
    //         console.log("deleteForm", args, result)
    //         cache.invalidate({ __typename: 'Form', id: args.uuid })
    //       },
    //     },
    //   },
    // }),
    retryExchange({}),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return subscriptionClient.request(operation)
      },
    }),
  ],
  fetchOptions: {
    // Das Backend verwendet die Browser ID für das Auditlogging. So wird nicht nur dokumentiert, welcher Account etwas getan hat, sondern auch von welchem Gerät aus
    headers: { 'X-Browser-Identifier': browser_id },
    credentials: 'same-origin',
  },
})

// feature check
export let settings = {
  features: [], // FIXME: default???
  context: null, // "staff", "inmate", "visitor", "external" or "internal"
  whitelistCategories: ['default', 'lawyer', 'counselling'],
  location: 'FI',
  ftlDebug: false,
  recorder: [],
  defaultLocale: 'eng',

  // ...

  check_feature: function (feature) {
    if (!feature) return true
    return this.features.includes(feature)
  },

  check_features: function (items) {
    if (isEmpty(items)) return true
    return intersection(this.features, items).length != 0
  },
}

// appstate store on localStorage
const appState = writable(JSON.parse(localStorage.getItem('appState') || '{}'))
let appState_uuid
user.subscribe((current) => (appState_uuid = current.uuid))
appState.subscribe((val) =>
  localStorage.setItem('appState', JSON.stringify(val))
)

// factory for stores backed by appState
export const userState = (key, dflt) => {
  const { subscribe } = derived(appState, ($appState) => {
    const val_ = get($appState, [appState_uuid, key], dflt)
    // console.log('get setting', key, val_, appState_uuid)
    return val_
  })

  return {
    subscribe,
    set: (val) => {
      appState.update((state) => {
        // console.warn('set setting', key, val, appState_uuid)
        set(state, [appState_uuid, key], val)
        return state
      })
    },
  }
}
