import 'fontsource-roboto/latin.css'
import './App.scss'
import App from './App.svelte'
import './App.scss'

// legacy browser don't like 'document' (nodeType 9) as root, but root = null does the same thing...
const foobar = window.IntersectionObserver
function IntersectionObserver(callback, opt_options) {
  if (opt_options.root && opt_options.root.nodeType == 9)
    opt_options.root = null
  return new foobar(callback, opt_options)
}
window.IntersectionObserver = IntersectionObserver

window.createApp = function (options) {
  return new App(options)
}
